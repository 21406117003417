import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

process.env?.REACT_APP_SENTRY_ENABLED?.toLowerCase() === 'true' &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development',
    integrations: [browserTracingIntegration()],
    tracesSampleRate: parseFloat(
      process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || '1.0'
    ),
    profilesSampleRate: parseFloat(
      process.env.REACT_APP_SENTRY_PROFILES_SAMPLE_RATE || '1.0'
    ),
  });


root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
