import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  Form,
  Upload,
  Input,
  Button,
  Radio,
  message,
  DatePicker,
  InputNumber,
  Image,
  Row,
  Col,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import AdminLayout from '../../components/layouts/AdminLayout/AdminLayout';
import { createHouse } from '../../api';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import EventLivePreview from '../../components/events/EventLivePreview';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';
import './../../App.css';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
const { RangePicker } = DatePicker;

const Create = () => {
  const navigate = useNavigate();
  const [loading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [hostFileList, setHostFileList] = useState([]);

  const handleHostsPreview = async (file, hostNumber) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // additional logic if needed for the specific host
  };

  const handleHostsChange =
    (hostNumber) =>
    async ({ fileList }) => {
      console.log('fileList--<>', fileList);

      // You might need to adjust the mapping logic based on the actual structure of fileList
      const updatedFileList = await Promise.all(
        fileList.map(async (file) => {
          if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
          }
          return file;
        })
      );

      console.log('updatedFileList=--=>', updatedFileList);

      setHostFileList((prevList) => {
        const newList = [...prevList];
        newList[hostNumber - 1] = updatedFileList[0];
        return newList;
      });

      formik.setFieldValue(
        `hosts[${hostNumber - 1}].image`,
        updatedFileList[0]?.response ? updatedFileList[0].response : {}
      );
    };

  const renderHost = (hostNumber) => (
    <Form.Item label={`HOST ${hostNumber}`}>
      <Row gutter={16} align="top">
        <Col xs={7} sm={5}>
          <Upload
            accept=".jpg,.png"
            multiple={false}
            action={`${process.env.REACT_APP_API_URL}/upload`}
            listType="picture-circle"
            fileList={
              hostFileList[hostNumber - 1]
                ? [hostFileList[hostNumber - 1]]
                : null
            }
            onPreview={(file) => handleHostsPreview(file, hostNumber)}
            onChange={handleHostsChange(hostNumber)}
          >
            {hostFileList[hostNumber - 1] ? null : uploadButton}
          </Upload>
        </Col>
        <Col xs={17} sm={19}>
          <Row align="middle" style={{ marginBottom: 16, marginTop: 8 }}>
            <Col xs={6}>Name:</Col>
            <Col xs={18}>
              <Input
                name={`hosts[${hostNumber - 1}].name`}
                onChange={formik.handleChange}
                value={formik.values.hosts[hostNumber - 1].name}
              />
            </Col>
          </Row>
          <Row align="middle">
            <Col xs={6}>Job Title:</Col>
            <Col xs={18}>
              <Input
                name={`hosts[${hostNumber - 1}].position`}
                onChange={formik.handleChange}
                value={formik.values.hosts[hostNumber - 1].position}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Item>
  );

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };

  const DraggableUploadListItem = ({ originNode, file }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: file.uid,
    });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: 'move',
    };

    const className = isDragging
      ? css`
          a {
            pointer-events: none;
          }
        `
      : '';
    return (
      <div
        ref={setNodeRef}
        style={style}
        className={className}
        {...attributes}
        {...listeners}
      >
        {file.status === 'error' && isDragging
          ? originNode.props.children
          : originNode}
      </div>
    );
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      images: [{ url: '' }],
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      description: Yup.string().required('Description is required'),
      images: Yup.array()
        .of(
          Yup.object().shape({
            url: Yup.string().required('Image is required'),
          })
        )
        .required('At least one image is required'),
    }),
    onSubmit: async (values) => {
      console.log(values);
      try {
        const response = await createHouse(values);
        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: 'House Created',
            text: 'The house has been created successfully.',
          });
          navigate('/houses');
        }
      } catch (error) {
        // Handle error response
      }
    },
  });

  const handleChange = ({ fileList: newFileList }) => {
    if (fileList.length <= 4) {
      setFileList(newFileList);
    } else {
      message.error('You can only upload up to 4 files!');
    }

    console.log('newFileList', newFileList);
    formik.setFieldValue(
      'images',
      newFileList.map((item) => item.response)
    );
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        const updatedFileList = arrayMove(prev, activeIndex, overIndex);

        setFileList(updatedFileList);
        formik.setFieldValue(
          'images',
          updatedFileList.map((file) => file.response)
        );
        return updatedFileList;
      });
    }
  };

  return (
    <AdminLayout>
      {console.log(formik.values)}
      {console.log(formik.errors)}
      <div className="container-fluid p-0">
        <h1 className="h3 mb-3">House</h1>
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Create From</h5>
              </div>
              <div className="card-body">
                <Form
                  {...{
                    labelCol: {
                      xs: { span: 24 },
                      sm: { span: 4 },
                    },
                    wrapperCol: {
                      xs: { span: 24 },
                      sm: { span: 20 },
                    },
                  }}
                >
                  <Form.Item
                    label="Cover"
                    validateStatus={formik.errors.images ? 'error' : ''}
                    help={
                      formik.errors.images &&
                      formik.errors.images.map((err, index) => (
                        <div key={index}>{err.url}</div>
                      ))
                    }
                  >
                    <Upload
                      accept=".jpg,.png"
                      multiple={false}
                      action={`${process.env.REACT_APP_API_URL}/upload`}
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      itemRender={(originNode, file) => (
                        <DraggableUploadListItem
                          originNode={originNode}
                          file={file}
                        />
                      )}
                    >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </Form.Item>

                  <Form.Item
                    label="Name"
                    validateStatus={formik.errors.name ? 'error' : ''}
                    help={formik.errors.name}
                  >
                    <Input
                      name="name"
                      placeholder="eg: Leveraging Advanced Technologies to Propel Your Business Forward"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                  </Form.Item>

                  <Form.Item
                    name="Description"
                    label="Description"
                    validateStatus={formik.errors.description ? 'error' : ''}
                    help={formik.errors.description}
                  >
                    <Input.TextArea
                      name="description"
                      showCount
                      maxLength={2000}
                      rows={8}
                      onChange={formik.handleChange}
                      value={formik.values.description}
                    />
                  </Form.Item>

                  <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
                    <Button type="primary" onClick={formik.handleSubmit}>
                      Create
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>

          {/* <EventLivePreview formik={formik} /> */}
        </div>
      </div>
    </AdminLayout>
  );
};

export default Create;
