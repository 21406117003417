import React from 'react';
import { Form, Carousel, Row, Col, Avatar, Typography } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Button } from 'antd';
const { Title, Text } = Typography;

dayjs.extend(utc);
dayjs.extend(timezone);

const EventLivePreview = ({ formik }) => {
  const descriptionLines = formik.values.description.split('\n');
  const requirementLines = formik.values.requirement.split('\n');
  return (
    <div className="col-12 col-lg-4 live-preview">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Live preview</h5>
        </div>
        <div className="card-body">
          {formik.values.images ? (
            <Carousel>
              {formik.values.images.map((file, index) => (
                <div key={index}>
                  {file && file.url ? (
                    <div className="image-container">
                      <img
                        src={file.url}
                        alt={`slide ${index}`}
                        className={`carousel-image ${
                          file.aspectRatio === 1
                            ? 'square-image'
                            : 'canvas-image'
                        }`}
                      />
                    </div>
                  ) : (
                    <p>No image available</p>
                  )}
                </div>
              ))}
            </Carousel>
          ) : null}
          <Form
            {...{
              labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
              },
              wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
              },
            }}
            onSubmit={formik.handleSubmit}
          >
            <div className="title">{formik.values.title}</div>
            {formik.values.openFrom && formik.values.openTo && (
              <div className="post-date">
                {dayjs(formik.values.openFrom).format('D MMMM YYYY HH:mm')}
                {dayjs(formik.values.openFrom).isSame(
                  dayjs(formik.values.openTo),
                  'day'
                )
                  ? ` - ${dayjs(formik.values.openTo).format('HH:mm')}`
                  : ` - ${dayjs(formik.values.openTo).format(
                      'D MMMM YYYY HH:mm'
                    )}`}
              </div>
            )}
            <div>{formik.values.venue}</div>

            <div className="event-type">
              {formik.values.isPaid ? 'Pay' : 'Free'}. Member
            </div>
            {formik.values.isPaid && (
              <div className="paid-amount">
                {formik.values.paidAmount} Baht include guest
              </div>
            )}
            <div className="height-36" />
            <div>Booking open</div>

            <div className="attendees">
              <span className="attendees-amount">
                0/{formik.values.capacity}
              </span>
              attendees
            </div>
            <div className="height-32" />
            <Button
              type="primary"
              block
              style={{
                background: 'black',
                color: 'white',
                borderRadius: '12px',
                fontFamily: 'Noto Sans, sans-serif',
                fontWeight: 600,
                fontSize: '16px',
                paddingTop: '16px',
                paddingBottom: '16px',
                height: 'auto',
              }}
            >
              Join Now
            </Button>

            <div className="height-36" />
            <div className="created-by">Speaker</div>
            {formik.values.hosts.map(
              (host, index) =>
                (host.name || host.position || host.image) && ( // Only render if name or position is not empty
                  <>
                    <div
                      key={index}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Col>
                        <Avatar
                          size={64}
                          src={host.image || '/assets/images/icon.png'}
                        />
                      </Col>
                      <Col>
                        <div style={{ marginLeft: '16px' }}>
                          {host.name && (
                            <Title level={5} style={{ margin: 0 }}>
                              {host.name}
                            </Title>
                          )}
                          {host.position && (
                            <Text type="secondary" style={{ margin: 0 }}>
                              {host.position}
                            </Text>
                          )}
                        </div>
                    </Col>
                    </div>
                    <div className="height-24" />
                  </>
                )
            )}

            <div className="height-24" />

            <div className="description-header">Description</div>
            <div>
              {descriptionLines.map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </div>

            <div className="height-24" />
            <div className="requirement-header">Requirement of attendees</div>
            <div>
              {requirementLines.map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </div>
            <div className="height-24" />
            <div className="created-by">Created by</div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar size={64} src={'/assets/images/icon.png'} />
              <div style={{ marginLeft: '16px' }}>
                <Title level={5} style={{ margin: 0 }}>
                  H.O.W. bangkok
                </Title>
                <Text type="secondary" style={{ margin: 0 }}>
                  Official
                </Text>
              </div>
            </div>
            <div className="height-32" />
            <Row>
              <Col span={12} className="last-updated">
                <div>Last update</div>
              </Col>
              <Col
                span={12}
                style={{ textAlign: 'right' }}
                className="updated-time"
              >
                <div>{dayjs().format('ddd, MMM D h:mm A')}</div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EventLivePreview;
