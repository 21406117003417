import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import AdminLayout from "../../components/layouts/AdminLayout/AdminLayout";
import { Link, useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import minMax from "dayjs/plugin/minMax";
import utc from "dayjs/plugin/utc";
import "./meet.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useQuery } from "@tanstack/react-query";
import { getMeetingRoom } from "../../api";
import { BOOKING_STATUS } from "../../commons/constant";
import { convertTo24RangeHours } from "../../until/date";
import {
  Modal,
  Image,
  Avatar,
  Button,
  Checkbox,
  Radio,
  Form,
  Input,
  Space,
  Divider,
  DatePicker,
  TimePicker,
  Select,
  InputNumber,
  Typography,
} from "antd";
import {
  TeamOutlined,
  EnvironmentOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import ContentEvent from "../../components/meetingRoom/ContentEvent";
import { getActiveMember } from "../../api/member";
import {
  getMeetingRoomById,
  getRoomTemplate,
  updateBookingRoom,
  createBookingRoom,
} from "../../api";
import {
  formatBookingDateToBangkokString,
  formatBookingDateTimeToBangkok,
  convertToBangkokStartOfDay,
} from "../../until/date";
import "moment-timezone";
import moment from "moment";
import { ETimezone } from "../../until/date";
dayjs.extend(
  timezone,
  isBetween,
  isSameOrAfter,
  isSameOrBefore,
  localeData,
  localizedFormat,
  minMax,
  utc
);
moment.tz.setDefault(ETimezone.BANGKOK);
dayjs.tz.setDefault(ETimezone.BANGKOK);
const localizer = momentLocalizer(moment);
const { TextArea } = Input;
const customSwal = Swal.mixin({
  customClass: {
    confirmButton: " btnSweetDanger",
    cancelButton: "btnSweetBlack",
  },
  buttonsStyling: false,
});
const CreateBooking = () => {
  const CANCEL_OVERLAPPING = "cancel-overlapping";
  const CONTINUE_OVERLAPPING = "continue-overlapping";
  const EXIT_OVERLAPPING = "exit";
  const [isModalSlot, setIsModalSlot] = useState(false);
  const [date, setDate] = useState(dayjs(new Date()).toDate());
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalNote, setIsModalNote] = useState(false);
  const [mainForm, setMainForm] = useState(false);
  const [roomSelect, setRoomSelect] = useState(false);
  const [readyGet, setReadyGet] = useState(false);
  const [oldData, setOldData] = useState({});
  const [isModalAttendees, setIsModalAttendees] = useState(false);
  const [isModalRoomDetail, setIsModalRoomDetail] = useState(false);
  const [roomDetail, setRoomDetail] = useState({});
  const [dateEvent, setDateEvent] = useState("");
  const [readyRoomSlot, setReadyRoomSlot] = useState(false);
  const [isSuccessConfirmed, setIsSuccessConfirmed] = useState(false);
  const [room, setRoom] = useState();

  const [formValue, setFormValue] = useState({});
  const [equipment, setEquipment] = useState([]);
  const [defaultEquipment, setDefaultEquipment] = useState([]);
  const [dateError, setDateError] = useState(false);
  const [TimeError, setTimeError] = useState(false);
  const [memberList, setMemberList] = useState(false);

  const topRef = useRef(null);

  let id = "";

  if (location.pathname.includes("edit-booking")) {
    const urlParts = location.pathname.split("/");
    id = urlParts[urlParts.length - 1];
  }
  const { data: booking, isSuccess: isBookingReady } = useQuery({
    queryKey: [`booking-data`, id],
    queryFn: () => getMeetingRoomById(id),
    enabled: readyGet,
  });

  const { data: rooms, isSuccess: isRoomsReady } = useQuery({
    queryKey: [`rooms-data`],
    queryFn: async () => await getRoomTemplate(""),
    enabled: true,
  });

  const { data: organizers, isSuccess: isOrganizersReady } = useQuery({
    queryKey: [`organizers-data`],
    queryFn: async () => await getMember(),
    enabled: true,
  });

  const getMember = async () => {
    let memberList = await getActiveMember();

    memberList = memberList.map((val) => ({
      ...val,
      label: val?.firstName + " " + val?.lastName,
      value: val.id,
    }));
    return memberList;
  };

  const createDateWithTime = (date, time) => {
    const [hours, minutes] = time.split(":").map(Number);
    let dateTime = moment(date)
      .set("hours", hours)
      .set("minutes", minutes)
      .toISOString();
    return dateTime;
  };

  const getOverlappingEvents = (timeInput, timeList) => {
    const inputStart = dayjs(timeInput.startTime);
    const inputEnd = dayjs(timeInput.endTime);

    return timeList.filter((item) => {
      if (item.id === id || item.status === BOOKING_STATUS.CANCELED) {
        return false;
      }
      const itemStart = dayjs(item.start);
      const itemEnd = dayjs(item.end);
      const inputStartsDuringItem =
        (inputStart.isAfter(itemStart) || inputStart.isSame(itemStart)) &&
        inputStart.isBefore(itemEnd);
      const inputEndsDuringItem =
        inputEnd.isAfter(itemStart) &&
        (inputEnd.isBefore(itemEnd) || inputEnd.isSame(itemEnd));
      const inputContainsItem =
        (inputStart.isBefore(itemStart) || inputStart.isSame(itemStart)) &&
        (inputEnd.isAfter(itemEnd) || inputEnd.isSame(itemEnd));

      return inputStartsDuringItem || inputEndsDuringItem || inputContainsItem;
    });
  };

  useEffect(() => {
    if (formValue && isSuccessConfirmed) {
      onConfirmToSubmitForm();
    }
  }, [formValue, isSuccessConfirmed]);

  const modalWarning = (overlappingEvents) => {
    const overlappingList = overlappingEvents
      .map((event, index) => {
        const startDate = new Date(event.start);
        const endDate = new Date(event.end);

        const timeStr = convertTo24RangeHours(startDate, endDate);
        const creatorName = `${event?.creator?.firstName} ${event?.creator?.lastName}`;

        return `
        <div class="overlap-item">
          <div class="overlap-checkbox">
            <input type="checkbox" id="cancel-event-${index}" class="event-checkbox" data-event-id="${event.id}" />
            <label for="cancel-event-${index}"></label>
          </div>
          <div class="overlap-content">
            <div class="event-title">${event?.title}</div>
            <div class="event-details">
              <div class="event-creator">
                <i class="fas fa-user"></i> ${creatorName}
              </div>
              <div class="event-time">
                <i class="far fa-clock"></i> ${timeStr}
              </div>
            </div>
          </div>
        </div>
      `;
      })
      .join("");
    const descriptionText =
      "You've selected a time slot that conflicts with existing reservations. Do you want to proceed anyway?";
    const modalHtml = `
     <div class="overlap-modal">
      <div class="overlap-title">${descriptionText}</div>
      <div class="overlap-list">
        ${overlappingList}
      </div>
      <div class="select-all-container" style="margin-top: 10px; text-align: left;">
        <input type="checkbox" id="select-all-events" />
        <label for="select-all-events">Select All</label>
      </div>
     </div>
     <style>
       .overlap-item {
         display: flex;
         align-items: flex-start;
         margin-bottom: 10px;
         padding: 10px;
         border: 1px solid #eee;
         border-radius: 5px;
       }
       .overlap-checkbox {
         margin-right: 10px;
         display: flex;
         align-items: center;
       }
       .overlap-content {
         flex: 1;
       }
       .event-title {
         font-weight: bold;
         margin-bottom: 5px;
       }
       .event-details {
         display: flex;
         justify-content: space-between;
         font-size: 0.9em;
         color: #666;
       }
     </style>
     <script>
       document.getElementById('select-all-events').addEventListener('change', function() {
         const checkboxes = document.querySelectorAll('.event-checkbox');
         checkboxes.forEach(checkbox => {
           checkbox.checked = this.checked;
         });
       });
     </script>
    `;
    return new Promise((resolve, reject) => {
      Swal.mixin({
        customClass: {
          confirmButton: "btnSweetDanger",
          cancelButton: "btnSweetBlack",
        },
        buttonsStyling: false,
      });
      customSwal
        .fire({
          title: "Booking Time Conflict",
          html: modalHtml,
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes",
          didOpen: () => {
            // Initialize the select all functionality
            const selectAllCheckbox =
              document.getElementById("select-all-events");
            const eventCheckboxes =
              document.querySelectorAll(".event-checkbox");

            selectAllCheckbox.addEventListener("change", function () {
              eventCheckboxes.forEach((checkbox) => {
                checkbox.checked = this.checked;
              });
            });
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // Get all selected events
            const selectedEventIds = Array.from(
              document.querySelectorAll(".event-checkbox:checked")
            ).map((checkbox) => checkbox.getAttribute("data-event-id"));
            if (selectedEventIds.length > 0) {
              resolve({
                action: CANCEL_OVERLAPPING,
                eventIds: selectedEventIds,
              });
            } else {
              resolve({
                action: CONTINUE_OVERLAPPING,
                eventIds: [],
              });
            }
          } else {
            resolve({
              action: EXIT_OVERLAPPING,
              eventIds: [],
            });
          }
        });
    });
  };

  const onConfirmToSubmitForm = () => {
    if (formValue) {
      const swalSubmit = Swal.mixin({
        customClass: {
          confirmButton: "btnSweetPrimary order-2 px-4",
          cancelButton: "btnSweetDanger order-1 right-gap px-4",
        },
        buttonsStyling: false,
      });
      const swalWarning = Swal.mixin({
        customClass: {
          confirmButton: "btnSweetBlack px-4",
        },
        buttonsStyling: false,
      });

      if (formValue.numberOfAttendees < memberData.length + 1) {
        swalWarning
          .fire({
            title: "You can’t create booking!",
            text: "Please manage information your attendees. ",
            icon: "warning",
            confirmButtonText: "Manage attendees",
            customClass: {
              confirmButton: "btnSweetGray px-4",
              title: "text-black",
              actions: "pb-5 pt-4",
            },
            buttonsStyling: false,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              setIsModalAttendees(true);
            }
          });
      } else {
        const isSelectedPast = moment(formValue.startTime).isBefore(moment());
        if (isSelectedPast) {
          swalWarning
            .fire({
              title: "The selected time is incorrect.",
              text: "You cannot choose a time in the past.",
              icon: "warning",
              confirmButtonText: "Edit time",
              customClass: {
                confirmButton: "btnSweetGray px-4",
                title: "text-black",
                actions: "pb-4 pt-4",
              },
              buttonsStyling: false,
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                // Swal.close();
                topRef.current.scrollIntoView({ behavior: "smooth" });
                setTimeError(true);
              }
            });
        } else {
          swalSubmit
            .fire({
              title: "Do you want to confirm meeting room?",
              text: "If you press confirm The system will immediately send booking details to the organizer of this booking.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Confirm",
              cancelButtonText: "Close",
              customClass: {
                confirmButton:
                  "btnSweetBlack px-5 event-confirm-attendees-check-in",
                cancelButton: "btnSweetDanger px-5",
                title: "text-black",
              },
              buttonsStyling: false,
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                onSubmitForm(result);
              }
            });
        }
      }
    }
  };

  const onSubmitForm = async (result) => {
    const swalAfter = Swal.mixin({
      customClass: {
        confirmButton: "btnSweetBlack order-2 px-4",
        cancelButton: "btnSweetBordered order-1 right-gap px-4",
      },
      buttonsStyling: false,
    });
    if (formValue) {
      if (location.pathname.includes("edit-booking")) {
        try {
          const response = await updateBookingRoom(formValue, id);
          if (response.status === 200) {
            swalAfter
              .fire({
                title: "Booking successful!",
                text: "",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "View calendar",
                cancelButtonText: "Booking datail",
              })
              .then(async (result) => {
                if (result.isConfirmed) {
                  Swal.close();
                  navigate(`/meeting-rooms`);
                } else {
                  Swal.close();
                  navigate(
                    `/meeting-rooms/?view=day&date=${moment(response.data.date)
                      .format("YYYY-MM-DD")
                      .toString()}&id=${response.data.id}`
                  );
                }
              });
          }
        } catch (error) {
          swalAfter
            .fire({
              title: "Room Unavailable!",
              text: "The system has cleared your booking information.",
              icon: "error",
              showCancelButton: true,
              confirmButtonText: "View calendar",
              cancelButtonText: "Create new booking",
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                Swal.close();
                navigate(`/meeting-rooms/`);
              } else {
                Swal.close();
                navigate(`/meeting-rooms/create-booking`);
              }
            });
        }
      } else {
        try {
          const response = await createBookingRoom(formValue);
          if (response.status === 201) {
            swalAfter
              .fire({
                title: "Booking successful!",
                text: "",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "View calendar",
                cancelButtonText: "Booking datail",
              })
              .then(async (result) => {
                if (result.isConfirmed) {
                  Swal.close();
                  navigate(`/meeting-rooms`);
                } else {
                  Swal.close();
                  navigate(
                    `/meeting-rooms/?view=day&date=${moment(response.data.date)
                      .format("YYYY-MM-DD")
                      .toString()}&id=${response.data.id}`
                  );
                }
              });
          }
        } catch {
          swalAfter
            .fire({
              title: "Room Unavailable!",
              text: "The system has cleared your booking information.",
              icon: "error",
              showCancelButton: true,
              confirmButtonText: "View calendar",
              cancelButtonText: "Create new booking",
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                Swal.close();
                navigate(`/meeting-rooms/`);
              } else {
                Swal.close();
                navigate(`/meeting-rooms/create-booking`);
              }
            });
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      date: booking?.data.date
        ? formatBookingDateToBangkokString(booking?.data.date)
        : dateEvent,
      room: booking?.data.room || room || {},
      allDay: booking?.data.allDay || false,
      startTime: booking?.data.startTime
        ? formatBookingDateTimeToBangkok(
            booking?.data.startTime,
            booking?.data.allDay
          )
        : "",
      endTime: booking?.data.endTime
        ? formatBookingDateTimeToBangkok(
            booking?.data.endTime,
            booking?.data.allDay
          )
        : "",
      title: booking?.data.title || "",
      equipments: booking?.data.equipments || room?.equipments || [],
      specialRequest: booking?.data.specialRequest || "",
      organizer: booking?.data.organizer || booking?.data.organizerId || "",
      numberOfAttendees: booking?.data.numberOfAttendees || 1,
      note: booking?.data.note || "",
      attendees: booking?.data?.attendees?.slice?.(1) || [],
      roomId: booking?.data.roomId,
    },
    isInitialValid: false,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnMount: false,
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter information."),
      organizer: Yup.object().required("Please enter information."),
      numberOfAttendees: Yup.string().required("Please enter information."),
    }),
    enableReinitialize: true,

    onSubmit: async (values) => {
      const startTime = createDateWithTime(values.date, values.startTime);
      const endTime = createDateWithTime(values.date, values.endTime);
      let cancelOverlappingEventIds = [];
      const timeInput = { startTime, endTime };
      const overlappingEvents = getOverlappingEvents(timeInput, roomTimeSlots);
      if (overlappingEvents?.length) {
        const { action, eventIds } = await modalWarning(overlappingEvents);
        if (action === CANCEL_OVERLAPPING) {
          cancelOverlappingEventIds = eventIds;
        } else if (action === EXIT_OVERLAPPING) {
          return;
        }
      }
      if (dateError) {
        return; // is has error date will be return
      }

      if (room) {
        setFormValue((prevValues) => {
          let timeSet = {
            startTime: null,
            endTime: null,
          };
          if (values?.startTime && values?.endTime) {
            timeSet.startTime = createDateWithTime(
              values.date,
              values.startTime
            );
            timeSet.endTime = createDateWithTime(values.date, values.endTime);
          }

          const prevVal = {
            ...prevValues,
            ...values,
            ...timeSet,
            roomId: room.id,
            attendees: [values.organizer, values.attendees].flat(),
            date: convertToBangkokStartOfDay(values.date),
            cancelOverlappingEventIds,
          };

          return prevVal;
        });
        setIsSuccessConfirmed(true);
      }
    },
  });

  useEffect(() => {
    if (formik.values.organizer) {
      const filteredOrganizers = organizers?.filter?.(
        (organizer) => organizer.id !== formik.values.organizer.id
      );
      setMemberList(filteredOrganizers);
    }
  }, [formik.values.organizer]);

  const {
    data: roomTimeSlots,
    isSuccess: isRoomTimeSlotsReady,
    refetch: refetchTimeSlot,
  } = useQuery({
    queryKey: [`room-slot`, formik.values.room.id, dateEvent],
    queryFn: () => {
      return getMeetingRoom(
        `fromDate=${getStartOfDay(
          formik.values.date
        ).toISOString()}&toDate=${getEndOfDay(
          formik.values.date
        ).toISOString()}&roomId=${formik?.values?.room?.id}`
      );
    },
    enabled: true,
  });
  const handleRefetch = () => {
    refetchTimeSlot();
  };

  useEffect(() => {
    if (location.pathname.includes("edit-booking")) {
      setReadyGet(true);
      if (isBookingReady) {
        setDateEvent(booking.data.date);
        setOldData(booking.data);
        setMainForm(true);
        setDate(dayjs(booking.data.date).toDate());
      }
    }
    if (booking?.data) {
      setEquipment(booking?.data.equipments);
      setDefaultEquipment(
        booking?.data.equipments
          ?.filter((element) => {
            return element.isSelected === true;
          })
          ?.map((a) => a.value)
      );
      const filterRoom = rooms?.filter?.((room) => {
        return room.id === booking?.data.roomId;
      });
      setRoom(filterRoom?.[0]);
      setMemberData(booking.data.attendees.slice(1));
    }
  }, [booking, isRoomsReady]);

  const dateBooking = (date, dateString) => {
    setDateEvent(date ? dayjs(date).toISOString() : null);
    formik.setFieldValue("date", date ? dayjs(date)?.toDate() : null);
    setDateError(!date);
  };
  const getDateEvent = async () => {
    if (dateEvent) {
      if (!dayjs(oldData.date).isSame(dayjs(dateEvent))) {
        //if date is change
        setDateError(false);
        setMainForm(false);
        setRoomSelect(true);
        let newOld = oldData;
        newOld.date = dateEvent;
        setOldData(newOld);
        formik.setFieldValue("date", dateEvent);
      }
    } else {
      setDateError(true);
    }
  };
  const handleToRoomSelect = () => {
    formik.resetForm();
    setRoom(null);
    setDateError(false);
    setMainForm(false);
    setRoomSelect(true);
    setEquipment([]);
    setMemberData([]);
  };
  const setTimerange = (date, dateString) => {
    formik.setFieldValue("startTime", dateString[0]);
    formik.setFieldValue("endTime", dateString[1]);
    setTimeError(false);
  };
  const getStartOfDay = (date) => {
    const start = moment(date).startOf("days");
    return start;
  };

  const getEndOfDay = (date) => {
    const end = moment(date).endOf("days");
    return end;
  };
  const handleOpenTimeSlot = async () => {
    await handleRefetch();
    setReadyRoomSlot(true);
    setIsModalSlot(true);
  };

  const chooseRoom = (e, i) => {
    if (e.resourceId !== "holiday") {
      setDefaultEquipment(
        e.equipments
          ?.filter((element) => {
            return element.isSelected === true;
          })
          ?.map((a) => a.value)
      );
    }

    setRoom(e);
    setEquipment(e.equipments);
    setMainForm(true);
    setRoomSelect(false);
  };
  const seeRoom = (e, i) => {
    setRoomDetail(e);
    setIsModalRoomDetail(true);
  };
  const closeNote = () => {
    setIsModalNote(false);
  };

  const [memberData, setMemberData] = useState([]);
  // useEffect(() => {
  //   setMemberData(formik.values.attendees);
  // }, []);
  const editAttendee = () => {
    setIsModalAttendees(true);
  };
  const editFieldMember = (event, e, i) => {
    const { name, value } = event.target;
    setMemberData((currentData) => {
      return currentData.map((elem, index) => {
        if (index !== i) return elem;
        e[name] = value;
        return e;
      });
    });
  };
  const delMem = (e, i) => {
    customSwal
      .fire({
        title: "Do you want to delete attendees?",
        text: "If you delete attendees The information you entered will be lost immediately.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          let newMem = [...memberData];
          newMem.splice(i, 1);
          setMemberData(newMem);
          Swal.fire({
            title: "Deleted!",
            text: "Your attendees has been deleted.",
            icon: "success",
          });
          setTimeout(() => {
            Swal.close();
          }, 1800);
        }
      });
  };

  const onConfirmCancelCreateBooking = () => {
    customSwal
      .fire({
        title: "Do you want to cancel room booking?",
        text: "If you press cancel The information you entered will be lost immediately.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          navigate(`/meeting-rooms`);
        } else {
          Swal.close();
        }
      });
  };
  const addAttendee = () => {
    let newMem = [...memberData];
    let obj = {
      label: "",
      value: Date.now().toString(),
      phone: "",
      email: "",
      type: "GUEST",
    };
    newMem.push(obj);

    setMemberData(newMem);
  };
  const selectMember = (option, e, i) => {
    setMemberData((currentData) => {
      return currentData.map((elem) => {
        if (elem.value !== e.value) return elem;
        return option;
      });
    });
  };
  const saveMember = () => {
    let isHasError = false;
    let newMem = [...memberData];
    newMem = newMem.map((elem) => {
      if (!elem?.label && elem.type === "GUEST") {
        elem.errorLabel = true;
        isHasError = true;
      } else {
        delete elem.errorLabel;
      }
      if (!elem?.email && elem.type === "GUEST") {
        elem.errorEmail = true;
        isHasError = true;
      } else {
        delete elem.errorEmail;
      }
      return elem;
    });
    if (isHasError) {
      setMemberData(newMem);
      return;
    }
    formik.setFieldValue("attendees", [...newMem]);
    setIsModalAttendees(false);
  };

  const { components, formats } = useMemo(
    () => ({
      formats: {
        timeGutterFormat: "HH:mm",
        weekdayFormat: (date, culture, localizer) =>
          localizer.format(date, "dd", culture),
        eventTimeRangeFormat: ({ start, end }) => {
          return convertTo24RangeHours(start, end);
        },
        selectRangeFormat: ({ start, end }) => {
          return convertTo24RangeHours(start, end);
        },
      },
    }),
    []
  );
  const onView = useCallback((newView) => {
    console.log(newView);
  });

  const updatedEquipments = (e) => {
    const updateItem = equipment.map((item) => {
      if (e.includes(item.value)) {
        return { ...item, isSelected: true };
      } else {
        return { ...item, isSelected: false };
      }
    });

    formik.setFieldValue("equipments", updateItem);
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const CustomEvent = ({ event }) => {
    const isCanceled = event.status === BOOKING_STATUS.CANCELED;
    const canceledText = isCanceled ? "(Canceled)" : "";
    return (
      <>
        <div className="rbc-event-content">
          <span>
            {event.title} {canceledText}
          </span>
        </div>
      </>
    );
  };

  const EventWrapper = ({ event, children }) => {
    const isCanceled = event.status === BOOKING_STATUS.CANCELED;
    const canceledStyle = isCanceled ? "time-slot-canceled" : "";
    return (
      <div data-event-id={event.id} className={canceledStyle}>
        {children}
      </div>
    );
  };

  const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);
  return (
    <AdminLayout>
      <main className="container-fluid p-0 create-meeting-wrap">
        <div className="d-flex justify-content-between align-items-center">
          <div className="h3 heading">Let's book now!</div>
        </div>
        <section>
          <div className="row m-0 g-0">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title meeting-room-card-title">
                    Please select date then press the search button.
                  </h5>
                </div>
                <div className="card-body">
                  <Form
                    labelCol={{
                      xs: { span: 24 },
                      sm: { span: 4 },
                    }}
                    wrapperCol={{
                      xs: { span: 24 },
                      sm: { span: 20 },
                    }}
                    labelAlign="left"
                    colon={false}
                  >
                    <Form.Item label={<label>Date</label>} required={true}>
                      <div className="row">
                        <div className="col-7">
                          <DatePicker
                            disabledDate={disabledDate}
                            onChange={dateBooking}
                            size="large"
                            style={{ width: "100%" }}
                            format={"ddd, D MMM YYYY"}
                            value={
                              dateEvent
                                ? dayjs(
                                    moment(dateEvent).format("YYYY-MM-DD"),
                                    "YYYY-MM-DD"
                                  ).tz(ETimezone.BANGKOK)
                                : null
                            }
                          />
                        </div>
                        <div className="col-5">
                          <Button
                            size="large"
                            shape="round"
                            className="btn btn-dark cursor-pointer"
                            onClick={getDateEvent}
                          >
                            Search
                          </Button>
                        </div>
                      </div>
                      {dateError && (
                        <span style={{ color: "red" }}>
                          Please select date.
                        </span>
                      )}
                    </Form.Item>

                    {/* room select */}
                    {roomSelect ? (
                      <div>
                        <div className="d-flex flex-column  gap-3 ">
                          {rooms.map((e, i) => (
                            <div
                              className="d-flex border border-1 p-2 rounded-md "
                              style={{ borderColor: "#E4E4E4" }}
                              key={e.id + i}
                            >
                              <Avatar
                                shape="square"
                                size={80}
                                src={e.imgThumb}
                              />
                              <div className="d-flex flex-column ps-3">
                                <strong className="text-lg">{e.name}</strong>
                                {e.resourceId.includes("holiday") ? (
                                  <></>
                                ) : (
                                  <span>
                                    <TeamOutlined /> {e.capacity}
                                  </span>
                                )}
                                <span>
                                  <EnvironmentOutlined /> {e.location}
                                </span>
                              </div>
                              <div className="ms-auto d-flex  flex-column  justify-content-center align-items-center gap-3">
                                <Button
                                  className="btn btn-dark px-5 cursor-pointer"
                                  shape="round"
                                  onClick={() => chooseRoom(e, i)}
                                >
                                  Add detail
                                </Button>
                                <a
                                  className="text-decoration-underline text-black cursor-pointer"
                                  onClick={() => seeRoom(e, i)}
                                >
                                  Room detail
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* end room select */}

                    {/* main form */}
                    {mainForm ? (
                      <>
                        <div
                          className="border border-1 rounded-md p-3"
                          style={{ borderColor: "#e3e3e3 !important" }}
                        >
                          <div>
                            <div className="pb-2">
                              <a
                                className="other-rooms-link"
                                onClick={handleToRoomSelect}
                              >
                                Other rooms
                              </a>
                            </div>
                            {formik.values.room.id ? (
                              <>
                                <div className="d-flex ">
                                  <Avatar
                                    shape="square"
                                    size={80}
                                    src={formik.values.room.imgThumb}
                                  />
                                  <div className="d-flex flex-column ps-3">
                                    <strong className="text-lg">
                                      {formik.values.room.name}
                                    </strong>
                                    <span>
                                      <TeamOutlined />{" "}
                                      {formik.values.room.capacity}
                                    </span>
                                    <span>
                                      <EnvironmentOutlined />{" "}
                                      {formik.values.room.location}
                                    </span>
                                  </div>
                                  <div className="ms-auto">
                                    <Button
                                      size="large"
                                      shape="round"
                                      className="px-5 btn btn-time-slot"
                                      onClick={() => handleOpenTimeSlot()}
                                    >
                                      Time slot
                                    </Button>
                                  </div>
                                </div>
                                <Divider />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <Space
                            direction="vertical"
                            size="middle"
                            style={{ width: "100%" }}
                          >
                            <div className="row">
                              <label className="col-2">
                                <strong>
                                  Date<span className="text-red">* </span>
                                </strong>
                              </label>
                              <div className="col-10">
                                <Input
                                  value={moment(formik.values.date).format(
                                    "ddd, DD MMM YYYY"
                                  )}
                                  disabled
                                />
                              </div>
                            </div>
                            <Form.Item
                              label="Time"
                              className="mb-0"
                              required={true}
                            >
                              <div ref={topRef}>
                                <Checkbox
                                  name="allDay"
                                  className="me-3"
                                  checked={formik.values.allDay}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "allDay",
                                      e.target.checked
                                    );
                                    formik.setFieldValue(
                                      "startTime",
                                      "00:00:00"
                                    );
                                    formik.setFieldValue("endTime", "23:59:59");
                                  }}
                                >
                                  All day
                                </Checkbox>
                                <TimePicker.RangePicker
                                  placeholder="Time start"
                                  disabled={formik.values.allDay}
                                  onChange={setTimerange}
                                  value={[
                                    dayjs(
                                      formik.values.startTime || "00:00",
                                      "HH:mm"
                                    ),
                                    dayjs(
                                      formik.values.endTime || "00:00",
                                      "HH:mm"
                                    ),
                                  ]}
                                  format="HH:mm"
                                />
                                {TimeError && (
                                  <div style={{ color: "red" }}>
                                    You cannot choose a time in the past.
                                  </div>
                                )}
                              </div>
                            </Form.Item>
                            <Form.Item
                              label="Title"
                              className="mb-0"
                              required={true}
                              rules={[{ required: true }]}
                              validateStatus={
                                formik.errors.title ? "error" : ""
                              }
                              help={formik.errors.title}
                            >
                              <Input
                                name="title"
                                count={{
                                  show: true,
                                  max: 100,
                                  exceedFormatter: (value, config) =>
                                    value.slice(0, config.max),
                                }}
                                placeholder="Add title"
                                onChange={formik.handleChange}
                                onBlur={(e) => {
                                  if (e.target.value) {
                                    formik.setFieldError("title", null);
                                  }
                                }}
                                value={formik.values.title}
                              />
                            </Form.Item>
                            <Form.Item label="Equipment" className="mb-0">
                              <Checkbox.Group
                                className="equipments-checkbox"
                                name="equipments"
                                onChange={(e) => {
                                  updatedEquipments(e);
                                }}
                                options={formik.values.equipments}
                                defaultValue={defaultEquipment}
                              ></Checkbox.Group>
                            </Form.Item>
                            <Form.Item
                              label={
                                <label
                                  style={{
                                    wordBreak: "break-word",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  Special Request
                                </label>
                              }
                              className="mb-0"
                            >
                              <TextArea
                                name="specialRequest"
                                rows={3}
                                placeholder="Add special request"
                                onChange={formik.handleChange}
                                value={formik.values.specialRequest}
                              />
                            </Form.Item>
                            <Divider />
                            <Form.Item
                              label="Organizer"
                              className="mb-0"
                              required={true}
                              validateStatus={
                                formik.errors.organizer ? "error" : ""
                              }
                              help={formik.errors.organizer}
                            >
                              <Select
                                name="organizer"
                                style={{ width: "100%" }}
                                placeholder="Choose organizer"
                                value={formik.values.organizer.id}
                                onChange={(value, obj) => {
                                  formik.setFieldValue("organizer", obj);
                                  if (obj) {
                                    formik.setFieldError("organizer", null);
                                  }
                                }}
                                options={organizers}
                                defaultValue={formik.values.organizer.id}
                                labelRender={(option) => (
                                  <Space>
                                    <span className="badge rounded-pill bg-light text-dark">
                                      {option.label}
                                    </span>
                                  </Space>
                                )}
                                optionRender={(option) => (
                                  <Space>
                                    <span
                                      role="img"
                                      aria-label={option.data.label}
                                    >
                                      <img
                                        alt=""
                                        src={option.data.avatar}
                                        className="rounded-circle"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                    </span>
                                    {option.data.label}
                                  </Space>
                                )}
                              />
                            </Form.Item>
                            <Form.Item
                              label={
                                <label
                                  style={{
                                    wordBreak: "break-word",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  Number of attendees
                                </label>
                              }
                              required={true}
                              className="mb-0"
                              validateStatus={
                                formik.errors.numberOfAttendees ? "error" : ""
                              }
                              help={formik.errors.numberOfAttendees}
                            >
                              <InputNumber
                                name="numberOfAttendees"
                                min={1}
                                max={30}
                                changeOnWheel
                                style={{ width: "100%" }}
                                placeholder="Enter the number of attendees"
                                value={formik.values.numberOfAttendees}
                                onChange={(value) => {
                                  formik.setFieldValue(
                                    "numberOfAttendees",
                                    value
                                  );
                                  if (value) {
                                    formik.setFieldError(
                                      "numberOfAttendees",
                                      null
                                    );
                                  }
                                }}
                              />
                            </Form.Item>
                            <div className="text-center row">
                              <div className="col-2"></div>
                              <div className="col-10">
                                <Button
                                  className="btn btn-dark px-5 pb-2 btn-manage-attendees"
                                  shape="round"
                                  onClick={() => editAttendee()}
                                >
                                  Manage attendees
                                </Button>
                              </div>
                            </div>
                            <Divider />
                            <Form.Item
                              label={
                                <label
                                  style={{
                                    wordBreak: "break-word",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  Note
                                  <span className="d-block text-xs text-secondary">
                                    Show in CMS
                                  </span>
                                </label>
                              }
                            >
                              <TextArea
                                name="note"
                                rows={3}
                                placeholder="Member 1 : likes to drink latte with 2 spoons of sugar. , etc"
                                onChange={(e) =>
                                  formik.setFieldValue("note", e.target.value)
                                }
                                value={formik.values.note}
                              />
                            </Form.Item>
                          </Space>
                        </div>
                        <div className="py-3 d-flex justify-content-between ">
                          <Link
                            size="large"
                            className="btn btn-link text-decoration-underline text-black"
                            onClick={() => onConfirmCancelCreateBooking()}
                          >
                            <strong>Cancel</strong>
                          </Link>
                          <Button
                            className="btn btn-primary py-2 px-5 h-auto fw-bold text-base"
                            style={{ borderRadius: "8px" }}
                            onClick={formik.handleSubmit}
                          >
                            {location.pathname.includes("edit-booking")
                              ? "Save"
                              : "Create"}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* end main form */}
                  </Form>
                </div>
              </div>
            </div>
            <div
              className="col"
              style={{ flex: "0 0 416px", maxWidth: "416px" }}
            >
              <div className="ms-3">
                <ContentEvent
                  view="create"
                  eventDetail={formik.values}
                  setIsModalNote={setIsModalNote}
                  equipments={equipment}
                  booking={room}
                  memberData={memberData}
                />
              </div>
            </div>
          </div>
        </section>

        {/* modal add attendee */}
        <Modal
          open={isModalAttendees}
          onCancel={() => setIsModalAttendees(false)}
          footer={""}
          title={
            <div className="text-center text-xl py-2">
              Attendees
              <span className="fw-normal d-block text-xs text-secondary ">
                You can add up to {formik.values.numberOfAttendees} attendees
              </span>
            </div>
          }
        >
          <div className="p-2 shadow bg-white d-flex mb-4">
            <div className="text-center fw-bold" style={{ flex: "0 0 40px" }}>
              1
            </div>
            <div className="">
              <ul className="list-inline fw-bold mb-0">
                <li>
                  Name : {formik.values.organizer.firstName}{" "}
                  {formik.values.organizer.lastName}{" "}
                  <span className="text-xs text-muted">(Organizer)</span>
                </li>
                <li>Tel : {formik.values.organizer.phone}</li>
                <li>Email : {formik.values.organizer.email}</li>
              </ul>
            </div>
          </div>

          {memberData &&
            memberData.map((e, i) => {
              return (
                <div
                  className="p-2 shadow-lg border border-1 bg-white d-flex mb-3 rounded-md "
                  style={{ borderColor: "#f8f8f8 !important" }}
                  key={`${i}_${e?.id || e?.value}`}
                >
                  <div
                    className="text-center fw-bold"
                    style={{ flex: "0 0 40px" }}
                  >
                    {i + 2}
                  </div>
                  <div className="w-100">
                    <div className="d-flex">
                      <Radio.Group
                        name="type"
                        value={e.type}
                        onChange={(event) => editFieldMember(event, e, i)}
                      >
                        <Radio value="MEMBER">Member</Radio>
                        <Radio value="GUEST">Guest</Radio>
                      </Radio.Group>
                      <div className="ms-auto">
                        <DeleteOutlined
                          className="text-danger cursor-pointer"
                          onClick={() => delMem(e, i)}
                        />
                      </div>
                    </div>
                    {e.type === "GUEST" ? (
                      <div className="pt-2">
                        <div className="row mb-2">
                          <label className="col-2 col-form-label">Name</label>
                          <div className="col-10">
                            <Input
                              status={e?.errorLabel ? "error" : null}
                              placeholder="Name attendee"
                              value={e.label}
                              name="label"
                              onChange={(event) => editFieldMember(event, e, i)}
                              onBlur={(e) => {
                                if (e.target.value) {
                                  setMemberData((prev) => {
                                    prev[i].errorLabel = null;
                                    return [...prev];
                                  });
                                }
                              }}
                            />

                            {e?.errorLabel && (
                              <Typography.Text type="danger">
                                Please enter information.
                              </Typography.Text>
                            )}
                          </div>
                        </div>
                        <div className="row mb-2">
                          <label className="col-2 col-form-label">Tel</label>
                          <div className="col-10">
                            <Input
                              placeholder="Tel attendee"
                              name="phone"
                              value={e.phone}
                              onChange={(event) => editFieldMember(event, e, i)}
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <label className="col-2 col-form-label">Email</label>
                          <div className="col-10">
                            <Input
                              status={e?.errorEmail ? "error" : null}
                              placeholder="Email attendee"
                              name="email"
                              value={e.email}
                              onChange={(event) => editFieldMember(event, e, i)}
                              onBlur={(e) => {
                                if (e.target.value) {
                                  setMemberData((prev) => {
                                    prev[i].errorEmail = null;
                                    return [...prev];
                                  });
                                }
                              }}
                            />
                            {e?.errorEmail && (
                              <Typography.Text type="danger">
                                Please enter information.
                              </Typography.Text>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="py-2">
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="select member"
                          onChange={(value, option) =>
                            selectMember(option, e, i)
                          }
                          options={memberList}
                          defaultValue={e.id}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          labelRender={(option) => (
                            <Space>
                              <span className="badge rounded-pill bg-light text-dark">
                                {option.label}
                              </span>
                            </Space>
                          )}
                          optionRender={(option) => (
                            <Space>
                              <span role="img" aria-label={option.data.label}>
                                <img
                                  src={option.data.avatar}
                                  className="rounded-circle"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </span>
                              {option.data.label}
                            </Space>
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}

          <div className="pt-4">
            <Button
              className="fw-bold py-2 h-auto w-100 btn btn-dark rounded-md"
              disabled={
                1 + memberData.length >= formik.values.numberOfAttendees
                  ? true
                  : false
              }
              onClick={() => addAttendee()}
            >
              Add attendees more
            </Button>
          </div>

          <div className="overflow-hidden pt-4" style={{ margin: "0 -24px" }}>
            <div
              className="text-center pt-3"
              style={{ boxShadow: "0 -5px 5px rgba(0,0,0,0.2)" }}
            >
              <Button
                size="large"
                shape="round"
                className="btn btn-dark"
                style={{ width: "180px" }}
                onClick={() => saveMember()}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>
        {/* end modal add attendee */}

        {/* modal time slot */}
        <Modal
          open={isModalSlot}
          onCancel={() => setIsModalSlot(false)}
          footer={""}
          title={<div className="text-center text-xl py-2">Time slot</div>}
        >
          <div>
            <Calendar
              className="customCalendar time-slot-calendar"
              date={moment(dateEvent).toDate()}
              formats={formats}
              localizer={localizer}
              events={roomTimeSlots}
              showAllEvents={true}
              dayLayoutAlgorithm={"no-overlap"}
              step={30}
              style={{ height: 550 }}
              onView={onView}
              onNavigate={onNavigate}
              view={"day"}
              toolbar={false}
              components={{
                event: CustomEvent,
                eventWrapper: EventWrapper,
              }}
            />
          </div>
          <div className="text-center pt-3">
            <Button
              type="danger"
              shape="round"
              className="px-5 btn btn-dark"
              onClick={() => setIsModalSlot(false)}
            >
              Close
            </Button>
          </div>
        </Modal>
        {/* end modal time slot */}

        {/* modal note */}
        <Modal
          open={isModalNote}
          width={350}
          onCancel={closeNote}
          footer=""
          zIndex={1002}
          title={
            <>
              <div className="text-center">Note</div>
            </>
          }
        >
          <div className="border border-1 border-light-subtle rounded-3 p-3">
            {formik.values.note}
          </div>
          <div className="text-center pt-3">
            <Button
              type="danger"
              shape="round"
              className="px-5 btn btn-dark"
              onClick={closeNote}
            >
              close
            </Button>
          </div>
        </Modal>
        {/* end modal note */}

        {/* modal room detail */}
        <Modal
          open={isModalRoomDetail}
          width={350}
          onCancel={() => setIsModalRoomDetail(false)}
          footer=""
          zIndex={1002}
          title={
            <>
              <div
                className="text-center room-detail-title"
                style={{ marginTop: "20px" }}
              >
                {roomDetail.name}
              </div>
            </>
          }
        >
          <div className="rounded-3">
            {roomDetail?.resourceId !== "holiday" ? (
              <>
                <Image
                  width={"100%"}
                  style={{
                    maxHeight: "250px",
                    overflow: "hidden",
                    objectFit: "cover",
                  }}
                  src={roomDetail.img}
                  fallback="https://fakeimg.pl/440x320/D9D9D9/D9D9D9/?retina=1&text=."
                />
                <div className="py-3 fw-bolder">Equipment</div>
                <div className="d-flex gap-2 flex-wrap  ">
                  {roomDetail?.equipments?.map((e, i) => (
                    <div
                      className="d-flex align-items-center border border-1 rounded-md py-1 px-3 bg-light"
                      key={e.value}
                    >
                      <img
                        src={e.icon}
                        style={{ width: "20px", height: "20px" }}
                      />
                      <span className="text-xs">{e.label}</span>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="text-center">
                  <Image
                    width={"219px"}
                    style={{
                      overflow: "hidden",
                      objectFit: "contain",
                      marginInline: "auto",
                    }}
                    src="/assets/images/holiday.png"
                  />
                </div>
                <div className="py-4 text-center">
                  This room is for setting today as a holiday. So that users
                  will not be able to book a room within today.
                </div>
              </>
            )}
          </div>
          <div className="text-center pt-4">
            <Button
              type="danger"
              shape="round"
              className="px-5 btn btn-dark cursor-pointer"
              onClick={() => setIsModalRoomDetail(false)}
            >
              Close
            </Button>
          </div>
        </Modal>
        {/* end modal room detail */}
      </main>
    </AdminLayout>
  );
};

export default CreateBooking;
