import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Link, Router, useSearchParams, useNavigate } from "react-router-dom";

import { PlusOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Modal, Radio, DatePicker, Space, Input, Form, Spin } from "antd";
import AdminLayout from "../../components/layouts/AdminLayout/AdminLayout";
import EventBar from "../../components/meetingRoom/EventBar";
import ModalTimeSlot from "../../components/meetingRoom/ModalTimeSlot";
import ModalDetailEvent from "../../components/meetingRoom/ModalDetailEvent";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import minMax from "dayjs/plugin/minMax";
import utc from "dayjs/plugin/utc";
import "./meet.css";
import { BOOKING_STATUS } from "../../commons/constant";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Calendar,
  Views,
  dayjsLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import { useQuery } from "@tanstack/react-query";
import {
  getMeetingRoom,
  getMeetingRoomById,
  getRoomTemplate,
  updateBookingRoomNote,
} from "../../api";
import "moment-timezone";
import moment from "moment";
import { ETimezone } from "../../until/date";
dayjs.extend(
  isBetween,
  isSameOrAfter,
  isSameOrBefore,
  localeData,
  localizedFormat,
  minMax,
  utc
);
moment.tz.setDefault(ETimezone.BANGKOK);
dayjs.tz.setDefault(ETimezone.BANGKOK);

const localizer = momentLocalizer(moment);
const Page = () => {
  const legends = [
    { key: "deanRoom", title: "Dean's office", color: "#7F56D9" },
    { key: "gameRoom", title: "Game's room", color: "#0072F9" },
    { key: "boardRoom", title: "Boardroom", color: "#000000" },
    { key: "bullRoom", title: "Bull's Den", color: "#FF2F2F" },
    { key: "golfRoom", title: "Golf simulator", color: "#269100" },
  ];
  const resources = [
    {
      id: "deanRoom",
      title: "Dean’s office",
    },
    {
      id: "gameRoom",
      title: "Game's room",
    },
    {
      id: "boardRoom",
      title: "Boardroom",
    },
    {
      id: "bullRoom",
      title: "Bull’s Den",
    },
    {
      id: "golfRoom",
      title: "Golf simulator",
    },
  ];
  const navigate = useNavigate();
  const [today, setDefaultDate] = useState(new Date());
  const [eventType, setEventType] = useState("month");
  const [isModalBooking, setIsModalBooking] = useState(false);
  const [isModalNote, setIsModalNote] = useState(false);
  const [fetchStatus, setFetchStatus] = useState(false);
  const [isModalBookingDetail, setIsModalBookingDetail] = useState(false);
  const [moveModal, setMoveModal] = useState("");
  const [loading, setLoading] = useState(true)

  const [viewCalendar, setViewCalendar] = useState(Views.MONTH);
  const [searchParams, setSearchParams] = useSearchParams();
  const view = searchParams.get("view") || Views.MONTH;
  const dateView = searchParams.get("date");
  const id = searchParams.get("id");
  const [bookingId, setBookingId] = useState("");
  const [fetchRoomTemplate, setFetchRoomTemplate] = useState(false);
  const [fetchUpdateNote, setFetchUpdateNote] = useState(false);
  const [date, setDate] = useState(dayjs(new Date()).toDate());
  const [fromDate, setFromDate] = useState(
    new Date(date?.getFullYear(), date?.getMonth(), 1)
  );
  const nextMonth = new Date(date?.getFullYear(), date?.getMonth() + 1, 1);
  const [toDate, setToDate] = useState(new Date(nextMonth - 1));

  const [eventSelect, setEventSelect] = useState({});
  const [eventDetailData, setEventDetailData] = useState({});

  const [fetchEventDetail, setFetchEventDetail] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [newFetchEvent, setNewFetchEvent] = useState(null);
  const [note, setNote] = useState({});
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const {
    data: events,
    isSuccess: isEventsReady,
    refetch: refetchGetMeetingRoom,
  } = useQuery({
    queryKey: [`event-list`, viewCalendar, newFetchEvent], //เพิ่ม newFetchEvent เข้ามาเพื่อให้ทำการ fetch ใหม่เวลามีการลบ booking
    queryFn: () => {
      const startDate = moment(fromDate)
        .startOf("days")
        .tz(ETimezone.BANGKOK)
        .toISOString();
      const endDate = moment(toDate)
        .endOf("months")
        .tz(ETimezone.BANGKOK)
        .toISOString();
      return getMeetingRoom(
        `fromDate=${startDate}&toDate=${endDate}&mode=${viewCalendar}`
      );
    },
    enabled: fetchStatus,
  });

  const { data: eventDetail, isSuccess: isEventDetailReady } = useQuery({
    queryKey: [`event-item`, bookingId || id],
    queryFn: () => getMeetingRoomById(bookingId || id),
    enabled: fetchEventDetail,
  });

  const { data: booking, isSuccess: isBookingReady } = useQuery({
    queryKey: [`room-data`, roomId],
    queryFn: () => {
      const startDate = moment(fromDate)
      .startOf("days")
      .tz(ETimezone.BANGKOK)
      .toISOString();

      return getRoomTemplate(
        `date=${startDate}&roomId=${roomId}`
      );
    },
    enabled: fetchRoomTemplate,
  });

  // const { data: updateNoteResponse, isSuccess: isUpdateNoteReady } = useQuery({
  //   queryKey: [`update-note-data`, id],
  //   queryFn: () => {
  //     return updateBookingRoomNote( note , id || eventDetail?.data?.id);
  //   },
  //   enabled: fetchUpdateNote,
  // });

  useEffect(() => {
    if (eventDetail) {
      setEventDetailData(eventDetail.data);
      setRoomId(eventDetail.data.roomId);
      form.setFieldValue("note", eventDetail?.data?.note || "");
    }
  }, [eventDetail]);

  useEffect(() => {
    if (roomId) {
      setFetchRoomTemplate(true);
    }
  }, [eventDetailData, roomId]);

  useEffect(() => {
    if (booking) {
      setIsModalBookingDetail(true);
      setFetchRoomTemplate(false);
    }
  }, [booking]);

  const WeekHeader = ({ label }) => {
    const dateLabel = label.split(" ");
    return (
      <div>
        <div className="text-xs">{dateLabel[1]}</div>
        <div className=" text-xl fw-bold ">{dateLabel[0]}</div>
      </div>
    );
  };
  const ResourceHd = ({ props }) => {
    let findLegend = legends.find((e) => {
      return e.key == props.resource.id;
    });
    return (
      <div className="rsHd d-flex align-items-center gap-2 p-2 px-3">
        <i style={{ backgroundColor: findLegend?.color }}></i>{" "}
        <span className="fw-medium text-base text-black">{props.label}</span>
      </div>
    );
  };

    const EventWrapper = ({ event, children }) => {
      const isCanceled = event?.event?.status === BOOKING_STATUS.CANCELED;
      const canceledStyle = isCanceled ? "time-slot-canceled" : "";
      return (
        <div data-event-id={event.id} className={canceledStyle}>
          {children}
        </div>
      );
    };
    

  const { components, formats } = useMemo(
    () => ({
      components: {
        eventWrapper: (event) => {
          return <EventWrapper style={{backgroundColor : 'pink'}} event={event}>
          {EventBar(
            event,
            legends,
            setEventSelect,
            setIsModalBooking,
            setIsModalBookingDetail,
            editBooking
          )}
          </EventWrapper>
        },
        resourceHeader: (props) => {
          return <ResourceHd props={props} />;
        },
        week: {
          header: WeekHeader,
        },
      },
      formats: {
        timeGutterFormat: "HH:mm",
        weekdayFormat: (date, culture, localizer) => {
          const day = localizer.format(date, "ddd", culture);

          switch (day) {
            case "Sun":
              return "SUN";
            case "Mon":
              return "MON";
            case "Tue":
              return "TUE";
            case "Wed":
              return "WED";
            case "Thu":
              return "THU";
            case "Fri":
              return "FRI";
            case "Sat":
              return "SAT";
            default:
              return day;
          }
        },
      },
    }),
    []
  );

  const editBooking = (id) => {
    if (!id) {
      return;
    }

    if (view === Views.MONTH) {
      setBookingId(id);
      setMoveModal("modalMove");
    } else {
      if (events) {
        const selectedItem = events.find((item) => item?.id?.includes?.(id));
        // setEventDetailData(selectedItem);
        setEventSelect(selectedItem);

        if (selectedItem?.bookingId && selectedItem?.roomId) {
          setBookingId(selectedItem.bookingId);
          setRoomId(selectedItem.roomId);
        } else {
          setBookingId(id);
        }
      } else {
        setBookingId(id);
        setFetchEventDetail(true);
      }
    }

    setFetchEventDetail(true);
  };

  const closeTimeList = () => {
    setMoveModal("");
    setIsModalBookingDetail(false);
    setIsModalBooking(false);
  };
  const closeDetail = () => {
    form.resetFields();
    setIsModalBookingDetail(false);
    setMoveModal("");
    setFetchRoomTemplate(false);
    setRoomId("");
    setFetchEventDetail(false);
  };
  const closeNote = () => {
    setIsModalNote(false);
  };
  const onView = useCallback(
    (newView) => {
      setViewCalendar(newView);
      setEventType(newView);
    },
    [eventType]
  );

  useEffect(() => {
    if (view) {
      setViewCalendar(view);
    }
    if (dateView) {
      setDate(dayjs(dateView).toDate());
    }

    setFetchStatus(true);
    if (id && isEventsReady) {
      setEventSelect(events[0]);
      editBooking(id);
      setFetchEventDetail(true);
    }
  }, [view, events]);

  const onNextClick = useCallback(() => {
    setLoading(true)
    clearParam();
    if (viewCalendar === Views.DAY) {
      const newDate = dayjs(date).add(1, "day").toDate();
      setDate(newDate);
      setFromDate(new Date(newDate));
      setToDate(new Date(newDate));
    }
    if (viewCalendar === Views.WEEK) {
      const newDate = dayjs(date).add(1, "week").toDate();
      let currentDay = newDate.getDay();

      let startOfWeek = new Date(newDate);
      startOfWeek.setDate(newDate.getDate() - currentDay);

      let endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      setDate(newDate);
      setFromDate(startOfWeek);
      setToDate(endOfWeek);
    }
    if (viewCalendar === Views.MONTH) {
      const newDate = dayjs(date).add(1, "month").toDate();
      const nextMonth = new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        1
      );

      setDate(newDate);
      setFromDate(new Date(newDate.getFullYear(), newDate.getMonth(), 1));
      setToDate(new Date(nextMonth - 1));
    }
  }, [viewCalendar, date]);

  const onBackClick = useCallback(() => {
    setLoading(true)
    clearParam();
    if (viewCalendar === Views.DAY) {
      const newDate = dayjs(date).subtract(1, "day").toDate();
      setDate(newDate);
      setFromDate(new Date(newDate));
      setToDate(new Date(newDate));
    }

    if (viewCalendar === Views.WEEK) {
      const newDate = dayjs(date).subtract(1, "week").toDate();
      let currentDay = newDate.getDay();

      let startOfWeek = new Date(newDate);
      startOfWeek.setDate(newDate.getDate() - currentDay);

      let endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      setDate(newDate);
      setFromDate(startOfWeek);
      setToDate(endOfWeek);
    }

    if (viewCalendar === Views.MONTH) {
      const newDate = dayjs(date).subtract(1, "month").toDate();
      const prevMonth = new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        1
      );
      setDate(newDate);
      setFromDate(new Date(newDate.getFullYear(), newDate.getMonth(), 1));
      setToDate(new Date(prevMonth - 1));
    }
  }, [viewCalendar, date]);

  const clearParam = () => {
    if (searchParams.has("date") || searchParams.has("id")) {
      searchParams.delete("date");
      searchParams.delete("id");
    }
  };

  const dateText = useMemo(() => {
    if (viewCalendar === Views.DAY) return dayjs(date).format("DD MMMM YYYY");
    if (viewCalendar === Views.WEEK) {
      const from = dayjs(date)?.startOf("week");
      const end = dayjs(date)?.endOf("week");
      return `${from.format("MMMM")} - ${end.format("MMMM")} ${end.format(
        "YYYY"
      )}`;
    }
    if (viewCalendar === Views.MONTH) {
      return dayjs(date).format("MMMM YYYY");
    }
  }, [viewCalendar, date]);
  const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);

  useEffect(() => {
    initialData()
  }, [fromDate, toDate]);

  const initialData = async ()=> {
    await refetchGetMeetingRoom();
    setLoading(false)
  }

  useEffect(() => {
    if (view === "day") {
      if (dateView) {
        setFromDate(dateView);
        setToDate(dateView);
      }
    }
  }, []);

  const onSelectDate = (date, dateString) => {
    clearParam();
    if(!date) {
      setDate(null);
      return ''
    }
    const newDate = dayjs(date).toDate();
    const nextMonth = new Date(
      newDate.getFullYear(),
      newDate.getMonth() + 1,
      1
    );

    setDate(newDate);
    setFromDate(new Date(newDate.getFullYear(), newDate.getMonth(), 1));
    setToDate(new Date(nextMonth - 1));
  };
  const changeView = (e) => {
    let params = {
      view: e.target.value,
    };
    if (dateView) {
      params.date = dateView;
    }
    if (id) {
      params.id = id;
    }
    setSearchParams(params);
    setViewCalendar(e.target.value);
    setEventType(e.target.value);
    delete params.id;
    setSearchParams(params);
  };

  const createEvent = async () => {
    navigate("/meeting-rooms/create-booking");
  };

  const submitNote = async (e) => {
    form.setFieldValue("note", e.note);
    await updateBookingRoomNote(e, id || eventDetail?.data?.id);
    setNote(e);
    setFetchUpdateNote(true);
    closeNote();
  };

  const handleDeleteBooking = () => {
    closeTimeList(false);
    setNewFetchEvent(new Date().getTime());
  };
  return (
    <AdminLayout>
      <main className="container-fluid p-0">
        <div className="d-flex justify-content-between align-items-center">
        <div className="h3 heading">Meeting Rooms</div>
          <div>
            <Button
              type="primary"
              size="large"
              icon={<PlusOutlined />}
              onClick={createEvent}
            >
              Book now
            </Button>
          </div>
        </div>
        <section className="mt-3">
          <div className="card rounded-4">
            <div className=" d-flex px-3 pt-3 pb-3 mb-4 justify-content-between align-items-center  toolbarCalendar border-bottom border-1  ">
              <div className=" d-flex align-items-center ">
                <Button type="text" onClick={onBackClick}>
                  <LeftOutlined
                    className=" text-secondary"
                    style={{ fontSize: "24px" }}
                  />
                </Button>
                <Button type="text" onClick={onNextClick}>
                  <RightOutlined
                    className=" text-secondary"
                    style={{ fontSize: "24px" }}
                  />
                </Button>
                <span className="text-xl date-text">{dateText === "Invalid Date" ? '': dateText}</span>
              </div>
              <div>
                <Space direction="horizontal" size={12}>
                  <DatePicker
                    onChange={onSelectDate}
                    needConfirm
                    size="large"
                    format={"DD MMMM YYYY"}
                  />
                  <Radio.Group
                    size="large"
                    value={viewCalendar}
                    onChange={(e) => changeView(e)}
                  >
                    <Radio.Button value={Views.DAY}>Day</Radio.Button>
                    <Radio.Button value={Views.WEEK}>Week</Radio.Button>
                    <Radio.Button value={Views.MONTH}>Month</Radio.Button>
                  </Radio.Group>
                </Space>
              </div>
            </div>
            <div className="calendar-wrap">
              <Spin spinning={loading}>
              <Calendar
                className="customCalendar"
                date={date}
                formats={formats}
                localizer={localizer}
                defaultDate={today}
                events={loading ? undefined : events}
                startAccessor="start"
                endAccessor="end"
                showMultiDayTimes
                showAllEvents={true}
                selectable
                dayLayoutAlgorithm={"no-overlap"}
                // step={60}
                style={{ height: 550 }}
                popup
                onView={onView}
                view={viewCalendar}
                toolbar={false}
                onNavigate={onNavigate}
                resources={viewCalendar == "day" ? resources : ""}
                components={components}
                
              />
              </Spin>
            </div>
          </div>
        </section>
        {/* legend */}
        <div className=" card rounded-4 p-4 mt-3">
          <h4 className="h4 heading">Room color highlights</h4>
          <div className="room-item d-flex gap-3 py-2">
            {legends.map((e, i) => (
              <div
                className={`d-flex align-items-center gap-2 pe-3 ${
                  legends.length == i + 1 ? "" : "border-end border-1"
                }`}
                key={"lg_" + i}
              >
                <i
                  className="d-block rounded-pill "
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: e.color,
                  }}
                ></i>{" "}
                <span>{e.title}</span>
              </div>
            ))}
          </div>
        </div>

        <ModalTimeSlot
          moveModal={moveModal}
          isModalBooking={isModalBooking}
          closeTimeList={closeTimeList}
          eventSelect={eventSelect}
          editBooking={editBooking}
        />

        {booking && eventDetailData && (
          <ModalDetailEvent
            isModalBookingDetail={isModalBookingDetail}
            closeDetail={closeDetail}
            eventDetail={eventDetailData}
            setIsModalNote={setIsModalNote}
            booking={booking.room}
            handleDeleteBooking={handleDeleteBooking}
          />
        )}
        <Modal
          open={isModalNote}
          width={350}
          onCancel={closeNote}
          footer=""
          zIndex={1002}
          title={
            <>
              <div className="text-center">Note</div>
            </>
          }
        >
          <Form form={form} onFinish={submitNote}>
            {eventDetail && (
              <Form.Item label="" name="note">
                <TextArea
                  rows={4}
                  defaultValue={eventDetail?.data?.note || ""}
                  maxLength={100}
                  style={{ resize: "none" }}
                />
              </Form.Item>
            )}

            <div className=" text-center pt-3">
              <Button
                htmlType="submit"
                type="danger"
                shape="round"
                className="px-5 btn btn-dark"
              >
                save
              </Button>
            </div>
          </Form>
        </Modal>
      </main>
    </AdminLayout>
  );
};

export default Page;
