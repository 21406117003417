import React, { useEffect, useState, useCallback } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";
import "./member.css";
import {
  Form,
  Upload,
  Input,
  Tabs,
  Image,
  Select,
  Col,
  Row,
  Button,
  Radio,
  Checkbox,
  message,
  DatePicker,
  InputNumber,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import AdminLayout from "../../components/layouts/AdminLayout/AdminLayout";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import EventLivePreview from "../../components/events/EventLivePreview";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { css } from "@emotion/css";
import { getMember, getMemberTiers, updateMember } from "../../api";
import "./../../App.css";
import * as Yup from "yup";
import Swal from "sweetalert2";
const { RangePicker } = DatePicker;

const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading] = useState(false);

  const [member, setMember] = useState();
  const [memberTiers, setMemberTiers] = useState([]);

  const [hostFileList, setHostFileList] = useState([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [isDelete, setIsDelete] = useState("")

  const { TextArea } = Input;

  // const selectCss = {
  //   width: '40%',
  // };

  // const inputCss = {
  //   width: '90%',
  // };

  // const handleHostsPreview = async (file, hostNumber) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  // };

  // const handleHostsChange =
  //   (hostNumber) =>
  //   async ({ fileList }) => {
  //     const updatedFileList = await Promise.all(
  //       fileList.map(async (file) => {
  //         if (!file.url && !file.preview) {
  //           file.preview = await getBase64(file.originFileObj);
  //         }
  //         return file;
  //       })
  //     );

  //     setHostFileList((prevList) => {
  //       const newList = [...prevList];
  //       newList[hostNumber - 1] = updatedFileList[0];
  //       return newList;
  //     });

  //     formik.setFieldValue(
  //       `hosts[${hostNumber - 1}].image`,
  //       updatedFileList[0]?.response ? updatedFileList[0].response : {}
  //     );
  //   };

  const fetchMember = useCallback(
    async (id) => {
      try {
        const memberResult = await getMember(id);
        setMember(memberResult.data);
        setFileList([
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: memberResult.data.avatar,
          },
        ]);
        // setFileList(memberResult.data.images);
        // if (memberResult.data.hosts) {
        //   setHostFileList(memberResult.data.hosts.map((host) => host.image));
        // }
      } catch (error) {
        console.error(error);
        // navigate('/events');
      }
    },
    [navigate]
  );

  const interestCheckboxes = [
    "Music",
    "Health & Wellness",
    "Food",
    "Sports",
    "Arts",
    "Traveling",
    "Investment",
    "️Exercise",
    "Books",
    "Wine",
    "Movie",
    "Style",
    "Politic",
    "Economy",
    "Technology",
    "Luxury items",
    "Gaming",
    "History",
  ];

  useEffect(() => {
    fetchMember(id);
    fetchMemberTiers();
  }, [fetchMember, id]);

  const formik = useFormik({
    initialValues: {
      tierId: member?.tierId || "",
      note: member?.note,
      // title: event?.title || '',
      // description: event?.description || '',
      // requirement: event?.requirement || '',
      // images: event?.images.length > 0 ? event?.images : [{ url: '' }],
      // venue: event?.venue || '',
      // capacity: event?.capacity || 0,
      // openFrom: event?.openFrom
      //   ? dayjs(event?.openFrom, 'YYYY-MM-DD HH:mm')
      //   : null,
      // openTo: event?.openTo ? dayjs(event?.openTo, 'YYYY-MM-DD HH:mm') : null,
      // registrationFrom: event?.registrationFrom
      //   ? dayjs(event?.registrationFrom, 'YYYY-MM-DD HH:mm')
      //   : null,
      // registrationTo: event?.registrationTo
      //   ? dayjs(event?.registrationTo, 'YYYY-MM-DD HH:mm')
      //   : null,
      // isPrivate: event?.isPrivate || false,
      // isPaid: event?.isPaid || false,
      // paidAmount: event?.paidAmount || 0,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      // title: Yup.string().required('Title is required'),
      // description: Yup.string().required('Description is required'),
      // images: Yup.array()
      //   .of(
      //     Yup.object().shape({
      //       url: Yup.string().required('Image is required'),
      //     })
      //   )
      //   .required('At least one image is required'),
    }),
    onSubmit: async (values) => {
      const isUpdated = Object.keys(values).some(key => values[key] !== formik.initialValues[key]);
      try {
        if (isUpdated) {
          const response = await updateMember(id, values);
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Member Updated",
              text: "The member has been updated successfully.",
            });
            navigate("/");
          }
        } else {
          navigate("/");
        }
      } catch (error) {
        // Handle error response
      }
    },
  });

  const fetchMemberTiers = async () => {
    const data = await getMemberTiers();
    console.log(data);
    setMemberTiers(data);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    formik.setFieldValue("avatar", newFileList[0]?.response?.url);
  };

  const handleBulletInput = (e) => {
    if (isDelete) {
      formik.setFieldValue("note", e.target.value);
    } else {
      formik.setFieldValue("note", e.target.value.replace(/(^|\n)(?!-)/g, '$1- '));
    }
  }

  return (
    <AdminLayout>
      <div className="container-fluid p-0">
        <h1 className="h3 mb-3">Member</h1>
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Edit From</h5>
              </div>

              {!member ? (
                <div>Loading...</div>
              ) : (
                <div className="card-body">
                  <Form onSubmit={formik.handleSubmit} layout="vertical">
                    <p className="mb-2">
                      <strong>Setting</strong>
                    </p>
                    <Row gutter={16}>
                      <Col lg={3} sm={5} xs={10}>
                        <Form.Item label="Tier">
                          <Select
                            onChange={(event) => {
                              formik.setFieldValue("tierId", event);
                            }}
                            options={[
                              {
                                value: "-",
                                label: "-",
                              },
                              ...memberTiers.map((tier) => ({
                                value: tier.id,
                                label: tier.name,
                              })),
                            ]}
                            value={formik.values.tierId || ""}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} xs={14}>
                        <Form.Item label="Member Period">
                          <RangePicker
                            onChange={(date, dateString) => {
                              formik.setFieldValue(
                                "periodStart",
                                dateString[0]
                              );
                              formik.setFieldValue("periodEnd", dateString[1]);
                            }}
                            defaultValue={
                              member.periodStart && member.periodEnd
                                ? [
                                    dayjs(member.periodStart),
                                    dayjs(member.periodEnd),
                                  ]
                                : []
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <p className="mb-2">
                      <strong>Information</strong>
                    </p>
                    <Row gutter={16}>
                      <Col lg={6} md={3} xs={24}>
                        <Form.Item label="Title">
                          <Input name="title" disabled value={member.title} />
                        </Form.Item>
                      </Col>

                      <Col lg={6} md={8} xs={24}>
                        <Form.Item label="First Name">
                          <Input
                            name="firstName"
                            disabled
                            value={member.firstName}
                          />
                        </Form.Item>
                      </Col>

                      <Col lg={6} md={5} xs={24}>
                        <Form.Item label="Middle Name">
                          <Input
                            name="middleName"
                            disabled
                            value={member.middleName}
                          />
                        </Form.Item>
                      </Col>

                      <Col lg={6} md={8} xs={24}>
                        <Form.Item label="Last Name">
                          <Input
                            name="lastName"
                            disabled
                            value={member.lastName}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={6} md={16} xs={24}>
                        <Form.Item label="Email">
                          <Input name="email" disabled value={member.email} />
                        </Form.Item>
                      </Col>

                      <Col lg={6} md={8} xs={24}>
                        <Form.Item label="Nick Name">
                          <Input
                            name="nickname"
                            disabled
                            value={member.nickname}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={6} md={8} xs={24}>
                        <Form.Item label="Birth Date">
                          <Input
                            name="birthDate"
                            disabled
                            value={dayjs(member.dateOfBirth).format(
                              "DD-MM-YYYY"
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <p className="mb-2">
                      <strong>Work</strong>
                    </p>
                    <Row gutter={16}>
                      <Col lg={6} md={12} xs={24}>
                        <Form.Item label="Company">
                          <Input
                            name="company"
                            disabled
                            value={member.company}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={6} md={12} xs={24}>
                        <Form.Item label="Job Title">
                          <Input
                            name="jobTitle"
                            disabled
                            value={member.jobTitle}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <p className="mb-2">
                      <strong>Contract</strong>
                    </p>
                    <Row gutter={16}>
                      <Col lg={6} md={12} xs={24}>
                        <Form.Item label="Phone">
                          <Input name="phone" disabled value={member.phone} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <p className="mb-2">
                      <strong>Mailing Address</strong>
                    </p>
                    <Row gutter={16}>
                      <Col lg={24} xs={24}>
                        <Form.Item label="Address">
                          <TextArea
                            name="address"
                            disabled
                            rows={4}
                            value={member.address}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={6} xs={24}>
                        <Form.Item label="Additional">
                          <Input name="apt" disabled value={member.apt} />
                        </Form.Item>
                      </Col>
                      <Col lg={6} md={6} xs={24}>
                        <Form.Item label="City">
                          <Input name="city" disabled value={member.city} />
                        </Form.Item>
                      </Col>

                      <Col lg={3} md={6} xs={24}>
                        <Form.Item label="Zip Code">
                          <Input
                            name="zipCode"
                            disabled
                            value={member.zipCode}
                          />
                        </Form.Item>
                      </Col>

                      <Col lg={3} md={6} xs={24}>
                        <Form.Item label="Country">
                          <Input
                            name="country"
                            disabled
                            value={member.country}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <p className="mb-2">
                      <strong>Assistant/Contact Person</strong>
                    </p>
                    <Row gutter={16}>
                      <Col lg={6} md={6} xs={24}>
                        <Form.Item label="First name">
                          <Input
                            name="assistantFirstName"
                            disabled
                            value={member.assistantFirstName}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={6} md={6} xs={24}>
                        <Form.Item label="Middle name">
                          <Input
                            name="assistantMiddleName"
                            disabled
                            value={member.assistantMiddleName}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={6} md={6} xs={24}>
                        <Form.Item label="Last name">
                          <Input
                            name="assistantLastName"
                            disabled
                            value={member.assistantLastName}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={6} md={6} xs={24}>
                        <Form.Item label="Phone no.">
                          <Input
                            name="assistantPhone"
                            disabled
                            value={member.assistantPhone}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <p className="mb-2">
                      <strong>Social Media</strong>
                    </p>
                    <Row gutter={16}>
                      <Col lg={6} md={12} xs={24}>
                        <Form.Item label="Linkedin">
                          <Input
                            name="linkedin"
                            disabled
                            value={member.linkedin}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={6} md={12} xs={24}>
                        <Form.Item label="Twitter">
                          <Input
                            name="twitter"
                            disabled
                            value={member.twitter}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={6} md={12} xs={24}>
                        <Form.Item label="Instagram">
                          <Input
                            name="instagram"
                            disabled
                            value={member.instagram}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={6} md={12} xs={24}>
                        <Form.Item label="Facebook">
                          <Input
                            name="facebook"
                            disabled
                            value={member.facebook}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <p className="mb-2">
                      <strong>Profile Picture</strong>
                    </p>
                    <Row gutter={16}>
                      <Col xs={12} md={12} lg={6}>
                        <Upload
                          className="custom-upload-avatar"
                          action={`${process.env.REACT_APP_API_URL}/upload`}
                          listType="picture-circle"
                          fileList={fileList}
                          onPreview={handlePreview}
                          onChange={handleChange}
                          multiple={false}
                          accept=".jpg,.jpeg,.png"
                        >
                          {fileList.length >= 1 ? null : uploadButton}
                        </Upload>
                      </Col>
                    </Row>
                    <p className="mb-2 mt-4">
                      <strong>Interest</strong>
                    </p>
                    <Row>
                      <Checkbox.Group
                        value={member.interests}
                        style={{
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        {interestCheckboxes.map((interest, index) => (
                          <Checkbox
                            value={interest}
                            key={index}
                            style={{
                              display: "flex",
                              width: "30%",
                            }}
                            disabled
                          >
                            {interest}
                          </Checkbox>
                        ))}
                      </Checkbox.Group>
                    </Row>
                    <p className="mb-2 mt-4">
                      <strong>Additional information</strong>
                    </p>

                    <Form.Item label="Note">
                      <TextArea
                        style={{ height: 144, resize: "none" }}
                        onChange={handleBulletInput}
                        onKeyDown={(e) => setIsDelete(e.key === 'Backspace')}
                        value={formik.values.note}
                        maxLength={200}
                      />
                    </Form.Item>
                    <Form.Item style={{ textAlign: "center" }}>
                      <Button type="primary" onClick={formik.handleSubmit}>
                        Update
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              )}
            </div>
          </div>

          {/* <EventLivePreview formik={formik} /> */}
        </div>
      </div>
    </AdminLayout>
  );
};

export default Edit;
