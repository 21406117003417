import * as dayjs from "dayjs";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

export const ETimezone = {
  BANGKOK: "Asia/Bangkok",
};

export const formatEventTimeToBangkok = (date) => {
  return dayjs(date).tz(ETimezone.BANGKOK).format("YYYY-MM-DD HH:mm");
};

export const convertToGMTPlus7 = (date) => {
  if (!date) return null;
  return date.replace(" ", "T") + ":00+07:00";
};

export const formatBookingDateToBangkokString = (bookingDate) => {
  return dayjs(bookingDate).tz(ETimezone.BANGKOK).toString();
};

export const formatBookingDateTimeToBangkok = (bookingDate) => {
  return dayjs(bookingDate).tz(ETimezone.BANGKOK).format("HH:mm:ss");
};

export const convertToBangkokStartOfDay = (date) => {
  return dayjs(date).tz(ETimezone.BANGKOK).startOf("day").format();
};

export const convertTo24Hours = (date) => {
  return dayjs(date).format("HH:mm");
};
export const convertTo24RangeHours = (start, end) => {
  return `${dayjs(start).format("HH:mm")} - ${dayjs(end).format("HH:mm")}`;
};
